import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import {
  getNews,
  getNewsSuccess,
  getNewsFailure,
  getNewsDetails,
  getNewsDetailsSuccess,
  getNewsDetailsFailure
} from '../../reducers/News/actions';


const GET_NEWS_URL = ({page}) => `${process.env.GATSBY_XELLA_BACKEND}/api/news?Page=${page}`;
const NEWS_DETAILS_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/news/${id}`;

function* getNewsSaga({payload}) {
  try {
    const res = yield getRequest(GET_NEWS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getNewsSuccess(body));
    }
  } catch (error) {
    yield put(getNewsFailure(error));
  }
}

function* getNewsDetailsSaga({payload}) {
  try {
    const res = yield getRequest(NEWS_DETAILS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getNewsDetailsSuccess(body));
    }
  } catch (error) {
    yield put(getNewsDetailsFailure(error));
  }
}

const newsSagas = [
  takeLatest(getNews, getNewsSaga),
  takeLatest(getNewsDetails, getNewsDetailsSaga)
];

export default newsSagas
