import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import { handleFileResponse } from '../../utils/helpers'

import {
  getArchitects,
  getArchitectsSuccess,
  getArchitectsFailure,
  getArchitectDetails,
  getArchitectDetailsSuccess,
  getArchitectDetailsFailure,
  getBuilders,
  getBuildersSuccess,
  getBuildersFailure,
  getBuildersDetails,
  getBuildersDetailsSuccess,
  getBuildersDetailsFailure,
  getDealers,
  getDealersSuccess,
  getDealersFailure,
  getDealersDetails,
  getDealersDetailsSuccess,
  getDealersDetailsFailure,
  filterService,
  filterServiceSuccess,
  filterServiceFailure
} from '../../reducers/Services/actions';

import { 
  getCities, 
  getCitiesSuccess, 
  getCitiesFailure,
  getDistricts,
  getDistrictsSuccess,
  getDistrictsFailure,
  postBePartner,
  postBePartnerSuccess,
  postBePartnerFailure,
  getLastNew,
  getLastArticle,
  getLastProject,
  getLastNewSuccess,
  getLastNewFailure,
  getLastArticleSuccess,
  getLastArticleFailure,
  getLastProjectSuccess,
  getLastProjectFailure
} from '../../reducers/Home/actions'

const ARCHITECTS_URL = ({page, cityId }) => 
`${process.env.GATSBY_XELLA_BACKEND}/api/architects?Page=${page}${cityId ? `&CityId=${cityId}` : ''}`;
const ARCHITECT_DETAILS_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/architects/${id}`;

const BUILDERS_URL = ({page, cityId}) => `${process.env.GATSBY_XELLA_BACKEND}/api/builders?Page=${page}${cityId ? `&CityId=${cityId}` : ''}`;
const BUILDERS_DETAILS_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/builders/${id}`;

const DEALERS_URL = ({page, cityId}) => `${process.env.GATSBY_XELLA_BACKEND}/api/dealers?Page=${page}${cityId ? `&CityId=${cityId}` : ''}`;
const DEALERS_DETAILS_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/dealers/${id}`;

const FILTER_SERVICE_URL = ({service, cityId, filters, page, districtId, discountId, guid}) => {
  let url = `${process.env.GATSBY_XELLA_BACKEND}/api/${service}?Page=${page}${cityId ? `&CityId=${cityId}` : ''}${districtId ? `&DistrictId=${districtId}` : ''}${discountId ? `&DiscountId=${discountId}` : '' }${guid ? `&Guid=${guid}` : '' }`
  filters && filters.forEach((filter, i) => {
    url = url + `&Filters=${filter}`
  })
  return url
};

const GET_CITIES_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/cities`;

const GET_DISTRICTS_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/districts/zip-codes`;

const GET_BE_PARTNER = ({partnerType, name, phone, email, location, gdprConsent, termsConsent}) => `${process.env.GATSBY_XELLA_BACKEND}/api/registration-files?${`partnerType=${partnerType}`}${name ? `&Name=${name}` : ''}${phone ? `&Phone=${phone}` : ''}${email ? `&Email=${email}` : ''}${location ? `&Location=${location}` : ''}${gdprConsent ? `&GdprConsent=${gdprConsent}` : ''}${termsConsent ? `&TermsConsent=${termsConsent}` : ''}`

const GET_LAST_URL = ({type}) => `${process.env.GATSBY_XELLA_BACKEND}/api/${type}/last`;


function* getArchitectsSaga({payload}) {
  try {
    const res = yield getRequest(ARCHITECTS_URL(payload));
    const body = yield call(handleResponse, res);
    
    if (!!res && res.status === 200) {
      yield put(getArchitectsSuccess(body));
    }
  } catch (error) {
    yield put(getArchitectsFailure(error));
  }
}

function* getArchitectDetailsSaga({payload}) {
  try {
    const res = yield getRequest(ARCHITECT_DETAILS_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200 && body) {
      yield put(getArchitectDetailsSuccess(body));
    }
  } catch (error) {
    yield put(getArchitectDetailsFailure(error));
  }
}


function* getBuildersSaga({payload}) {
  try {
    const res = yield getRequest(BUILDERS_URL(payload));
    const body = yield call(handleResponse, res);
    
    if (!!res && res.status === 200) {
      yield put(getBuildersSuccess(body));
    }
  } catch (error) {
    yield put(getBuildersFailure(error));
  }
}

function* getBuildersDetailsSaga({payload}) {
  try {
    const res = yield getRequest(BUILDERS_DETAILS_URL(payload));
    const body = yield call(handleResponse, res);
  
    if (!!res && res.status === 200) {
      yield put(getBuildersDetailsSuccess(body));
    }
  } catch (error) {
    yield put(getBuildersDetailsFailure(error));
  }
}

function* getDealersSaga({payload}) {
  try {
    const res = yield getRequest(DEALERS_URL(payload));
    const body = yield call(handleResponse, res);
    
    if (!!res && res.status === 200) {
      yield put(getDealersSuccess(body));
    }
  } catch (error) {
    yield put(getDealersFailure(error));
  }
}

function* getDealersDetailsSaga({payload}) {
  try {
    const res = yield getRequest(DEALERS_DETAILS_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getDealersDetailsSuccess(body));
    }
  } catch (error) {
    yield put(getDealersDetailsFailure(error));
  }
}

function* filterServiceSaga ({payload}) {
  try {
    const res = yield getRequest(FILTER_SERVICE_URL(payload));
    let body = yield call(handleResponse, res);
    body.service = payload.service
    if (!!res && res.status === 200) {
      yield put(filterServiceSuccess(body));
    }
  } catch (error) {
    yield put(filterServiceFailure(error));
  }
}

function* getCitiesSaga () {
  try {
    const res = yield getRequest(GET_CITIES_URL());
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getCitiesSuccess(body));
    }
  } catch (error) {
    yield put(getCitiesFailure(error));
  }
}

function* getDistrictsSaga () {
  try {
    const res = yield getRequest(GET_DISTRICTS_URL());
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getDistrictsSuccess(body));
    }
  } catch (error) {
    yield put(getDistrictsFailure(error));
  }
}

function* postBePartnerSaga ({payload}) {
  try {
    const res = yield getRequest(GET_BE_PARTNER(payload));
    // const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      handleFileResponse(res, payload.partnerType)
      // typeof(window) !== 'undefined' && window.open(GET_BE_PARTNER(payload), "_blank")

      yield put(postBePartnerSuccess(payload));
    }
    if (!!res && res.status === 400) {
      yield put(postBePartnerFailure());
    }
  } catch (error) {
    yield put(postBePartnerFailure(error));
  }
}

function* getLastNewSaga ({payload}) {
  try {
    const res = yield getRequest(GET_LAST_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getLastNewSuccess(body));
    }
  } catch (error) {
    yield put(getLastNewFailure(error));
  }
}

function* getLastArticleSaga ({payload}) {
  try {
    const res = yield getRequest(GET_LAST_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getLastArticleSuccess(body));
    }
  } catch (error) {
    yield put(getLastArticleFailure(error));
  }
}

function* getLastProjectSaga ({payload}) {
  try {
    const res = yield getRequest(GET_LAST_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getLastProjectSuccess(body));
    }
  } catch (error) {
    yield put(getLastProjectFailure(error));
  }
}


const homeSagas = [
  takeLatest(getArchitects, getArchitectsSaga),
  takeLatest(getArchitectDetails, getArchitectDetailsSaga),
  takeLatest(getBuilders, getBuildersSaga),
  takeLatest(getBuildersDetails, getBuildersDetailsSaga),
  takeLatest(getDealers, getDealersSaga),
  takeLatest(getDealersDetails, getDealersDetailsSaga),
  takeLatest(filterService, filterServiceSaga),
  takeLatest(getCities, getCitiesSaga),
  takeLatest(getDistricts, getDistrictsSaga),
  takeLatest(postBePartner, postBePartnerSaga),
  takeLatest(getLastNew, getLastNewSaga),
  takeLatest(getLastArticle, getLastArticleSaga),
  takeLatest(getLastProject, getLastProjectSaga)
]

export default homeSagas;
