import produce from "immer";
import * as actionTypes from "./actionTypes";

const defaultState = {
  data: null,
  total_pages: null,
  current_page: 1,
  filters: [],
  details: null,
  selectedFilters: []
};

const architects = produce((draft, { type, payload }) => {
  const newState = draft;

  switch (type) {
    case actionTypes.GET_ARCHITECTS_SUCCESS:
      newState.data = payload.architects.items;
      newState.current_page = payload.architects.current_page;
      newState.total_pages = payload.architects.total_pages;
      newState.filters = payload.filters;
      break;

    case actionTypes.GET_ARCHITECT_DETAILS_SUCCESS:
      newState.details = payload;
      break;

    case actionTypes.GET_BUILDERS_SUCCESS:
      newState.data = payload.builders.items;
      newState.current_page = payload.builders.current_page;
      newState.total_pages = payload.builders.total_pages;
      newState.filters = payload.filters;
      break;

    case actionTypes.GET_BUILDERS_DETAILS_SUCCESS:
      newState.details = payload;
      break;

    case actionTypes.GET_DEALERS_SUCCESS:
      newState.data = payload.dealers.items;
      newState.current_page = payload.dealers.current_page;
      newState.total_pages = payload.dealers.total_pages;
      newState.filters = payload.filters;
      break;

    case actionTypes.GET_DEALERS_DETAILS_SUCCESS:
      newState.details = payload;
      break;

    case actionTypes.FILTER_SERVICE_SUCCESS:
      let serviceType = payload.service;
      newState.data = payload[serviceType].items;
      newState.current_page = payload[serviceType].current_page;
      newState.total_pages = payload[serviceType].total_pages;
      newState.filters = payload.filters;
      break;

    case actionTypes.SET_FILTERS:
      newState.selectedFilters = payload
      break;
      
    case actionTypes.RESET_CURRENT_PAGE:
      newState.current_page = 1;
      break;
      
    default:
  }
}, defaultState);

export default architects;
