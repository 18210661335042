import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import {
  getPresentations,
  getPresentationsSuccess,
  getPresentationsFailure,
  getPresentationDetails,
  getPresentationDetailsSuccess,
  getPresentationDetailsFailure
} from '../../reducers/Presentations/actions';


const GET_PRESENTATIONS_URL = ({page}) => `${process.env.GATSBY_XELLA_BACKEND}/api/presentations?Page=${page}`;
const PRESENTATIONS_DETAILS_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/presentations/${id}`;

function* getPresentationsSaga({payload}) {
  try {
    const res = yield getRequest(GET_PRESENTATIONS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getPresentationsSuccess(body));
    }
  } catch (error) {
    yield put(getPresentationsFailure(error));
  }
}

function* getPresentationsDetailsSaga({payload}) {
  try {
    const res = yield getRequest(PRESENTATIONS_DETAILS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      typeof(window) !== 'undefined' && window.open(PRESENTATIONS_DETAILS_URL(payload), "_blank")
      yield put(getPresentationDetailsSuccess(body));
    }
  } catch (error) {
    yield put(getPresentationDetailsFailure(error));
  }
}

const presentationsSagas = [
  takeLatest(getPresentations, getPresentationsSaga),
  takeLatest(getPresentationDetails, getPresentationsDetailsSaga)
]

export default presentationsSagas
