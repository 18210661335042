import {createActions} from 'redux-actions';
import * as actionTypes from './actionTypes';

export const {
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  postProduct,
  postProductSuccess,
  postProductFailure,
  getProductsDeclaration,
  getProductsDeclarationSuccess,
  getProductsDeclarationFailure
} = createActions(
  actionTypes.GET_PRODUCTS,
  actionTypes.GET_PRODUCTS_SUCCESS,
  actionTypes.GET_PRODUCTS_FAILURE,
  actionTypes.POST_PRODUCT,
  actionTypes.POST_PRODUCT_SUCCESS,
  actionTypes.POST_PRODUCT_FAILURE,
  actionTypes.GET_PRODUCTS_DECLARATION,
  actionTypes.GET_PRODUCTS_DECLARATION_SUCCESS,
  actionTypes.GET_PRODUCTS_DECLARATION_FAILURE
);
