import {put, takeLatest } from 'redux-saga/effects';
import { 
  postRequest,
  putRequest
} from '../../utils/requests'

import {
  postRequestData,
  postRequestDataSuccess,
  postRequestDataFailure,
  postProjectRequest,
  postProjectRequestSuccess,
  postProjectRequestFailure,
  postFieldCounter,
  postFieldCounterSuccess,
  postFieldCounterFailure
} from '../../reducers/Requests/actions';

import {setModal} from '../../reducers/Home/actions'


const POST_DATA_URL = ({service}) => `${process.env.GATSBY_XELLA_BACKEND}/api/${service}/send-inquiry`;
const POST_PRPOJECT_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/projects/send-inquiry`;
const POST_VIEW_URL = ({type, id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/${type}/views/${id}`;

function* postDataSaga({payload}) {
  try {
    const res = yield postRequest(POST_DATA_URL(),payload);
    // const body = yield call(handleResponse, res);

    if (!!res && res.status === 204) {
      yield put(postRequestDataSuccess());
    }
  } catch (error) {
    yield put(postRequestDataFailure(error));
  }
}

function* postProjectRequestSaga({payload}) {
  try {
    const res = yield postRequest(POST_PRPOJECT_URL(payload), payload);
    // const body = yield call(handleResponse, res);

    if (!!res && res.status === 204) {
      yield put(setModal())
      yield put(postProjectRequestSuccess());
    }
    if(res && res.status === 400) {
     yield put(postProjectRequestFailure());
    }
  } catch (error) {
    yield put(postProjectRequestFailure(error));
  }
}

function* postFieldCounterSaga({payload}) {
  try {
    const res = yield putRequest(POST_VIEW_URL(payload), payload);

    if (!!res && res.status === 204) {
      yield put(postFieldCounterSuccess());
    }
  } catch (error) {
    yield put(postFieldCounterFailure(error));
  }
}

const requestSagas = [
  takeLatest(postRequestData, postDataSaga),
  takeLatest(postProjectRequest, postProjectRequestSaga),
  takeLatest(postFieldCounter, postFieldCounterSaga)
]

export default requestSagas
