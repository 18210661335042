import {createActions} from 'redux-actions'
import * as actionTypes from './actionTypes'

export const {
  getPresentations,
  getPresentationsSuccess,
  getPresentationsFailure,
  getPresentationDetails,
  getPresentationDetailsSuccess,
  getPresentationDetailsFailure,
} = createActions(
  actionTypes.GET_PRESENTATIONS,
  actionTypes.GET_PRESENTATIONS_SUCCESS,
  actionTypes.GET_PRESENTATIONS_FAILURE,
  actionTypes.GET_PRESENTATION_DETAILS,
  actionTypes.GET_PRESENTATION_DETAILS_SUCCESS,
  actionTypes.GET_PRESENTATION_DETAILS_FAILURE
)
