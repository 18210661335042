import { createActions } from "redux-actions";
import * as actionTypes from "./actionTypes";

export const {
  setServiceType,
  setCity,
  setDistrict,
  getCities,
  getCitiesSuccess,
  getCitiesFailure,
  getDistricts,
  getDistrictsSuccess,
  getDistrictsFailure,
  postBePartner,
  postBePartnerSuccess,
  postBePartnerFailure,
  getLastNew,
  getLastNewSuccess,
  getLastNewFailure,
  getLastArticle,
  getLastArticleSuccess,
  getLastArticleFailure,
  getLastProject,
  getLastProjectSuccess,
  getLastProjectFailure,
  generateNewGuid,
  setBePartner,
  setModal
} = createActions(
  actionTypes.SET_SERVICE_TYPE,
  actionTypes.SET_CITY,
  actionTypes.SET_DISTRICT,
  actionTypes.GET_CITIES,
  actionTypes.GET_CITIES_SUCCESS,
  actionTypes.GET_CITIES_FAILURE,
  actionTypes.GET_DISTRICTS,
  actionTypes.GET_DISTRICTS_SUCCESS,
  actionTypes.GET_DISTRICTS_FAILURE,
  actionTypes.POST_BE_PARTNER,
  actionTypes.POST_BE_PARTNER_SUCCESS,
  actionTypes.POST_BE_PARTNER_FAILURE,
  actionTypes.GET_LAST_NEW,
  actionTypes.GET_LAST_NEW_SUCCESS,
  actionTypes.GET_LAST_NEW_FAILURE,
  actionTypes.GET_LAST_ARTICLE,
  actionTypes.GET_LAST_ARTICLE_SUCCESS,
  actionTypes.GET_LAST_ARTICLE_FAILURE,
  actionTypes.GET_LAST_PROJECT,
  actionTypes.GET_LAST_PROJECT_SUCCESS,
  actionTypes.GET_LAST_PROJECT_FAILURE,
  actionTypes.GENERATE_NEW_GUID,
  actionTypes.SET_BE_PARTNER,
  actionTypes.SET_MODAL
);
