export const GET_ARCHITECTS = "GET_ARCHITECTS";
export const GET_ARCHITECTS_SUCCESS = "GET_ARCHITECTS_SUCCESS";
export const GET_ARCHITECTS_FAILURE = "GET_ARCHITECTS_FAILURE";

export const GET_ARCHITECT_DETAILS = "GET_ARCHITECT_DETAILS";
export const GET_ARCHITECT_DETAILS_SUCCESS = "GET_ARCHITECT_DETAILS_SUCCESS";
export const GET_ARCHITECT_DETAILS_FAILURE = "GET_ARCHITECT_DETAILS_FAILURE";

export const GET_BUILDERS = "GET_BUILDERS";
export const GET_BUILDERS_SUCCESS = "GET_BUILDERS_SUCCESS";
export const GET_BUILDERS_FAILURE = "GET_BUILDERS_FAILURE";

export const GET_BUILDERS_DETAILS = "GET_BUILDERS_DETAILS";
export const GET_BUILDERS_DETAILS_SUCCESS = "GET_BUILDERS_DETAILS_SUCCESS";
export const GET_BUILDERS_DETAILS_FAILURE = "GET_BUILDERS_DETAILS_FAILURE";

export const GET_DEALERS = "GET_DEALERS";
export const GET_DEALERS_SUCCESS = "GET_DEALERS_SUCCESS";
export const GET_DEALERS_FAILURE = "GET_DEALERS_FAILURE";

export const GET_DEALERS_DETAILS = "GET_DEALERS_DETAILS";
export const GET_DEALERS_DETAILS_SUCCESS = "GET_DEALERS_DETAILS_SUCCESS";
export const GET_DEALERS_DETAILS_FAILURE = "GET_DEALERS_DETAILS_FAILURE";

export const FILTER_SERVICE = "FILTER_SERVICE";
export const FILTER_SERVICE_SUCCESS = "FILTER_SERVICE_SUCCESS";
export const FILTER_SERVICE_FAILURE = "FILTER_SERVICE_FAILURE";

export const SET_FILTERS = 'SET_FILTERS'

export const RESET_CURRENT_PAGE = "RESET_CURRENT_PAGE";
