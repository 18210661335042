export const GET_DISCOUNTS = 'GET_DISCOUNTS' 
export const GET_DISCOUNTS_SUCCESS = 'GET_DISCOUNTS_SUCCESS' 
export const GET_DISCOUNTS_FAILURE = 'GET_DISCOUNTS_FAILURE' 

export const GET_SINGLE_DISCOUNT = 'GET_SINGLE_DISCOUNT' 
export const GET_SINGLE_DISCOUNT_SUCCESS = 'GET_SINGLE_DISCOUNT_SUCCESS' 
export const GET_SINGLE_DISCOUNT_FAILURE = 'GET_SINGLE_DISCOUNT_FAILURE' 

export const POST_DISCOUNT = 'POST_DISCOUNT' 
export const POST_DISCOUNT_SUCCESS = 'POST_DISCOUNT_SUCCESS' 
export const POST_DISCOUNT_FAILURE = 'POST_DISCOUNT_FAILURE' 

export const SET_DISCOUNT_ID = 'SET_DISCOUNT_ID'