import React from 'react'

const createFormData = (dataObject) => {
  const formData = new FormData();
  buildFormData(formData, dataObject);
  return formData;
};

export default createFormData;
function buildFormData(formData, data) {
  Object.keys(data).forEach(key => {
    if(!data[key]) return;
    if (Array.isArray(data[key])) {
      buildArrayFormData(formData, data[key], key);
    } else if (data[key].rawFile) {
      formData.append(key, data[key].rawFile);
    } else {
      formData.append(key, data[key]);
    }
  });
}

const buildArrayFormData = (formData, data, parentKey) => {
  Object.keys(data).forEach(key => {
    if(!data[key]) return;
    formData.append(parentKey, data[key][0]);
    // if (typeof data[key] === 'object') {
    //   buildObjectFormData(formData, data[key], `${parentKey}[${key}]`);
    // } else {
     
    // }
  });
}

export const handleFileResponse = (response, partner) => {
  response.blob().then(blob => {
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      switch(partner) {
        case 'architect': 
          link.download = 'architect.pdf';
        break;

        case 'builder':
          link.download = 'builder.pdf';
        break;

        default: link.download = `${partner}.pdf`;
        break;
      }
      
      link.click();
  })
}

export const returnArchitectName = (name, id) => {
  if(id) {
    return (
    <a 
      href={`https://www.building-companion.bg/service-details?id=${id}&service=architects`} 
      className='blue-link ml-2'
    >
    {name}
    </a>
    )
  } else return name
  }
  