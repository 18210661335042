import {createActions} from 'redux-actions'
import * as actionTypes from './actionTypes'

export const {
  getGuides,
  getGuidesSuccess,
  getGuidesFailure,
  postData,
  postDataSuccess,
  postDataFailure,
  setUserName,
  setUserPhone,
  setUserMail,
  setUserLocation,
  setUserData
} = createActions(
  actionTypes.GET_GUIDES,
  actionTypes.GET_GUIDES_SUCCESS,
  actionTypes.GET_GUIDES_FAILURE,
  actionTypes.POST_DATA,
  actionTypes.POST_DATA_SUCCESS,
  actionTypes.POST_DATA_FAILURE,
  actionTypes.SET_USER_DATA
)
