import news from './News/index';
import home from './Home/index'
import services from './Services';
import articles from './Articles/index'
import presentations from './Presentations'
import videos from './Videos'
import projects from './Projects'
import guides from './BuildingGuide'
import discounts from './Discounts';
import map from './Map'
import requests from './Requests';
import terms from './Terms'
import aboutInfo from './About';
import products from './Products';

export default {
  news,
  home,
  services,
  articles,
  presentations,
  videos,
  projects,
  guides,
  discounts,
  map,
  requests,
  terms,
  aboutInfo,
  products
};
