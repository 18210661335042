export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const SET_CITY = 'SET_CITY' 
export const SET_DISTRICT = 'SET_DISTRICT' 

export const GET_CITIES = 'GET_CITIES' 
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS' 
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE' 

export const GET_DISTRICTS = 'GET_DISTRICTS'
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS'
export const GET_DISTRICTS_FAILURE = 'GET_DISTRICTS_FAILURE'

export const POST_BE_PARTNER = 'POST_BE_PARTNER' 
export const POST_BE_PARTNER_SUCCESS = 'POST_BE_PARTNER_SUCCESS' 
export const POST_BE_PARTNER_FAILURE = 'POST_BE_PARTNER_FAILURE'

export const GET_LAST_NEW = 'GET_LAST_NEW' 
export const GET_LAST_NEW_SUCCESS = 'GET_LAST_NEW_SUCCESS' 
export const GET_LAST_NEW_FAILURE = 'GET_LAST_NEW_FAILURE' 

export const GET_LAST_ARTICLE = 'GET_LAST_ARTICLE' 
export const GET_LAST_ARTICLE_SUCCESS = 'GET_LAST_ARTICLE_SUCCESS' 
export const GET_LAST_ARTICLE_FAILURE = 'GET_LAST_ARTICLE_FAILURE' 

export const GET_LAST_PROJECT = 'GET_LAST_PROJECT' 
export const GET_LAST_PROJECT_SUCCESS = 'GET_LAST_PROJECT_SUCCESS' 
export const GET_LAST_PROJECT_FAILURE = 'GET_LAST_PROJECT_FAILURE' 

export const GENERATE_NEW_GUID = 'GENERATE_NEW_GUID'

export const SET_BE_PARTNER = 'SET_BE_PARTNER' 
export const SET_MODAL = 'SET_MODAL'