import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import {
  getProjects,
  getProjectsSuccess,
  getProjectsFailure,
  getProjectDetails,
  getProjectDetailsSuccess,
  getProjectDetailsFailure,
} from '../../reducers/Projects/actions';


const setBasementValue = (v) => {
  if (v === 'Да'){
    return true
  } else  return false
}

const GET_PROJECTS_URL = ({page, floor, minArea, maxArea, basement}) => `${process.env.GATSBY_XELLA_BACKEND}/api/projects?Page=${page}${floor ? `&Floor=${floor}` : ''}${minArea || minArea === 0 ? `&MinArea=${minArea}` : ''}${maxArea ? `&MaXArea=${maxArea}` : ''}${basement && basement !== 'Всички' ? `&Basement=${setBasementValue(basement)}` : ''}`;
const PROJECT_DETAILS_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/projects/${id}`;

function* getProjectsSaga({payload}) {
  try {
    const res = yield getRequest(GET_PROJECTS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getProjectsSuccess(body));
    }
  } catch (error) {
    yield put(getProjectsFailure(error));
  }
}

function* getProjectDetailsSaga({payload}) {
  try {
    const res = yield getRequest(PROJECT_DETAILS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getProjectDetailsSuccess(body));
    }
  } catch (error) {
    yield put(getProjectDetailsFailure(error));
  }
}

const projectsSagas = [
  takeLatest(getProjects, getProjectsSaga),
  takeLatest(getProjectDetails, getProjectDetailsSaga)
]

export default projectsSagas
