import {createActions} from 'redux-actions'
import * as actionTypes from './actionTypes'

export const {
  getDiscounts,
  getDiscountsSuccess,
  getDiscountsFailure,
  postDiscount,
  postDiscountSuccess,
  postDiscountFailure,
  setDiscountId,
  getSingleDiscount,
  getSingleDiscountSuccess,
  getSingleDiscountFailure
} = createActions(
  actionTypes.GET_DISCOUNTS,
  actionTypes.GET_DISCOUNTS_SUCCESS,
  actionTypes.GET_DISCOUNTS_FAILURE,
  actionTypes.POST_DISCOUNT,
  actionTypes.POST_DISCOUNT_SUCCESS,
  actionTypes.POST_DISCOUNT_FAILURE,
  actionTypes.SET_DISCOUNT_ID,
  actionTypes.GET_SINGLE_DISCOUNT,
  actionTypes.GET_SINGLE_DISCOUNT_SUCCESS,
  actionTypes.GET_SINGLE_DISCOUNT_FAILURE
)
