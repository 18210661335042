export const GET_ABOUT_INFO = 'GET_ABOUT_INFO' 
export const GET_ABOUT_INFO_SUCCESS = 'GET_ABOUT_INFO_SUCCESS' 
export const GET_ABOUT_INFO_FAILURE = 'GET_ABOUT_INFO_FAILURE' 

export const GET_CONTACTS_INFO = 'GET_CONTACTS_INFO' 
export const GET_CONTACTS_INFO_SUCCESS = 'GET_CONTACTS_INFO_SUCCESS' 
export const GET_CONTACTS_INFO_FAILURE = 'GET_CONTACTS_INFO_FAILURE' 

export const POST_CONTACTS_INFO = 'POST_CONTACTS_INFO' 
export const POST_CONTACTS_INFO_SUCCESS = 'POST_CONTACTS_INFO_SUCCESS' 
export const POST_CONTACTS_INFO_FAILURE = 'POST_CONTACTS_INFO_FAILURE' 

export const GET_FAQ_INFO = 'GET_FAQ_INFO' 
export const GET_FAQ_INFO_SUCCESS = 'GET_FAQ_INFO_SUCCESS' 
export const GET_FAQ_INFO_FAILURE = 'GET_FAQ_INFO_FAILURE' 