export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const POST_PRODUCT = 'POST_PRODUCT';
export const POST_PRODUCT_SUCCESS = 'POST_PRODUCT_SUCCESS';
export const POST_PRODUCT_FAILURE = 'POST_PRODUCT_FAILURE';

export const GET_PRODUCTS_DECLARATION = 'GET_PRODUCTS_DECLARATION'
export const GET_PRODUCTS_DECLARATION_SUCCESS = 'GET_PRODUCTS_DECLARATION_SUCCESS'
export const GET_PRODUCTS_DECLARATION_FAILURE = 'GET_PRODUCTS_DECLARATION_FAILURE'