import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse,
  postRequest
} from '../../utils/requests'

import { handleFileResponse } from '../../utils/helpers'


import {
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  postProduct,
  postProductSuccess,
  postProductFailure,
  getProductsDeclaration,
  getProductsDeclarationSuccess,
  getProductsDeclarationFailure
} from '../../reducers/Products/actions';


const GET_PRODUCTS_URL = ({type}) => `${process.env.GATSBY_XELLA_BACKEND}/api/products/${type}/all`;
const POST_PRODUCT_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/products/send-inquiry`;
const PRODUCTS_DECLARATION_URL = ({type}) => `${process.env.GATSBY_XELLA_BACKEND}/api/products/declaration/download/${type}`

function* getProductsSaga({payload}) {
  try {
    const res = yield getRequest(GET_PRODUCTS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getProductsSuccess(body));
    }
  } catch (error) {
    yield put(getProductsFailure(error));
  }
}

function* postProductSaga({payload}) {
  try {
    const res = yield postRequest(POST_PRODUCT_URL(),payload);
    // const body = yield call(handleResponse, res);

    if (!!res && res.status === 204) {
      yield put(postProductSuccess())
    }
  } catch (error) {
    yield put(postProductFailure(error));
  }
}

function* getProductsDeclarationSaga ({payload}) {
  try {
    const res = yield getRequest(PRODUCTS_DECLARATION_URL(payload));

    if (!!res && res.status === 200) {
      typeof(window) !== 'undefined' && window.open(PRODUCTS_DECLARATION_URL(payload), "_blank")
      yield put(getProductsDeclarationSuccess());
    }
  } catch (error) {
    yield put(getProductsDeclarationFailure(error));
  }
}

const productsSagas = [
  takeLatest(getProducts, getProductsSaga),
  takeLatest(postProduct, postProductSaga),
  takeLatest(getProductsDeclaration, getProductsDeclarationSaga)
]

export default productsSagas
