import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import {
  getMapData,
  getMapDataSuccess,
  getMapDataFailure
} from '../../reducers/Map/actions';


const GET_MAP_DATA_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/districts`;

function* getMapDataSaga() {
  try {
    const res = yield getRequest(GET_MAP_DATA_URL());
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getMapDataSuccess(body));
    }
  } catch (error) {
    yield put(getMapDataFailure(error));
  }
}

const mapDataSagas = [
  takeLatest(getMapData, getMapDataSaga)
];

export default mapDataSagas
