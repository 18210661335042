import {createActions} from 'redux-actions'
import * as actionTypes from './actionTypes'

export const {
  getAboutInfo,
  getAboutInfoSuccess,
  getAboutInfoFailre,
  getContactsInfo,
  getContactsInfoSuccess,
  getContactsInfoFailure,
  postContactsInfo,
  postContactsInfoSuccess,
  postContactsInfoFailure,
  getFaqInfo,
  getFaqInfoSuccess,
  getFaqInfoFailure
} = createActions(
  actionTypes.GET_ABOUT_INFO,
  actionTypes.GET_ABOUT_INFO_SUCCESS,
  actionTypes.GET_ABOUT_INFO_FAILURE,
  actionTypes.GET_CONTACTS_INFO,
  actionTypes.GET_CONTACTS_INFO_SUCCESS,
  actionTypes.GET_CONTACTS_INFO_FAILURE,
  actionTypes.POST_CONTACTS_INFO,
  actionTypes.POST_CONTACTS_INFO_SUCCESS,
  actionTypes.POST_CONTACTS_INFO_FAILURE,
  actionTypes.GET_FAQ_INFO,
  actionTypes.GET_FAQ_INFO_SUCCESS,
  actionTypes.GET_FAQ_INFO_FAILURE
)
