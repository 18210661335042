import { createActions } from "redux-actions";
import * as actionTypes from "./actionTypes";

export const {
  getMapData,
  getMapDataSuccess,
  getMapDataFailure
} = createActions(
  actionTypes.GET_MAP_DATA,
  actionTypes.GET_MAP_DATA_SUCCESS,
  actionTypes.GET_MAP_DATA_FAILURE
);
