import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse,
  postRequest
} from '../../utils/requests'

import {
  getDiscounts,
  getDiscountsSuccess,
  getDiscountsFailure,
  postDiscount,
  postDiscountSuccess,
  postDiscountFailure,
  getSingleDiscount,
  getSingleDiscountSuccess,
  getSingleDiscountFailure
} from '../../reducers/Discounts/actions';

import { setModal } from '../../reducers/Home/actions'


const GET_DISCOUNTS_URL = ({page}) => `${process.env.GATSBY_XELLA_BACKEND}/api/discounts?Page=${page}`;
const GET_SINGLE_DISCOUNT_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/discounts/${id}`;
const POST_DISCOUNT_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/discounts/send`;

function* getDiscountsSaga({payload}) {
  try {
    const res = yield getRequest(GET_DISCOUNTS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getDiscountsSuccess(body));
    }
  } catch (error) {
    yield put(getDiscountsFailure(error));
  }
}

function* getSingleDiscountSaga({payload}) {
  try {
    const res = yield getRequest(GET_SINGLE_DISCOUNT_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getSingleDiscountSuccess(body));
    }
  } catch (error) {
    yield put(getSingleDiscountFailure(error));
  }
}

function* postDiscountSaga({payload}) {
  try {
    const res = yield postRequest(POST_DISCOUNT_URL(), payload);
    // const body = yield call(handleResponse, res);

    if (!!res && res.status === 204) {
      yield put(setModal()) 
      yield put(postDiscountSuccess());
    }
  } catch (error) {
    yield put(postDiscountFailure(error));
  }
}

const discountsSagas = [
  takeLatest(getDiscounts, getDiscountsSaga),
  takeLatest(postDiscount, postDiscountSaga),
  takeLatest(getSingleDiscount, getSingleDiscountSaga),
];

export default discountsSagas
