import produce from 'immer';
import * as aboutActionTypes from './actionTypes';

const defaultState = {
  aboutInfo: null,
  contacts: null,
  faq: null
}

const aboutInfo = produce((draft, {type, payload}) => {
  const newState = draft;

  switch (type) {
   
      case aboutActionTypes.GET_ABOUT_INFO_SUCCESS:
        newState.aboutInfo = payload
        break;

      case aboutActionTypes.GET_CONTACTS_INFO_SUCCESS:
        newState.contacts = payload
        break;

      case aboutActionTypes.GET_FAQ_INFO_SUCCESS:
        newState.faq = payload
        break;
 
    default:
  }
}, defaultState);

export default aboutInfo;
