/* eslint-disable max-classes-per-file */

const buildHeaders = additionalHeaders => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });

  Object.keys(additionalHeaders).forEach(key => {
    headers.append(key, additionalHeaders[key]);
  });

  if (true) {
    // IE check here
    headers.append('Pragma', 'no-cache');
    headers.append('Cache-Control', 'no-cache');
    headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
  }

  return headers;
};

const buildRequestOptions = (method, requestBody, requestOptions = {}) => {
  const {headers, defaultHeaders = true, ...otherOptions} = requestOptions;
  const sessionId = localStorage.getItem('access_token');
  const requestHeaders = defaultHeaders
    ? buildHeaders({
        ...headers,
        ...(!!sessionId && {'X-A1-SessionId': sessionId})
      })
    : headers;

  return {
    method,
    ...otherOptions,
    headers: requestHeaders,
    ...(!!requestBody && {
      body:
        typeof requestBody === 'object'
          ? JSON.stringify(requestBody)
          : requestBody
    })
  };
};

export const getRequest = (url, otherOptions) =>
  fetch(url, buildRequestOptions('GET', null, otherOptions));
export const postRequest = (url, body, otherOptions) =>
  fetch(url, buildRequestOptions('POST', body, otherOptions));
export const patchRequest = (url, body, otherOptions) =>
  fetch(url, buildRequestOptions('PATCH', body, otherOptions));
export const putRequest = (url, body, otherOptions) =>
  fetch(url, buildRequestOptions('PUT', body, otherOptions));
export const deleteRequest = (url, body, otherOptions) =>
  fetch(url, buildRequestOptions('DELETE', body, otherOptions));

const getParser = headers => {
  const contentType = headers.get('Content-Type');
  return !!contentType && contentType.includes('application/json')
    ? 'json'
    : 'text';
};

const parse = res => res[getParser(res.headers)]();

export const handleError = (t, error, options = {}) =>
  t(`translation:${error.ErrorCode}`, {...options, ...error.Properties});

export function* handleResponse(res) {
  const body = yield parse(res);
  if (res.ok) {
    return body;
  } else if (res.status === 401) {
    throw new UnauthorizedException();
  } else if (res.status === 404) {
    throw new NotFoundException('Resource not found.');
  } else {
    throw body;
  }
}

class Exception {
  constructor(message) {
    this.message = message;
  }
}

export class NotFoundException extends Exception {}
export class ForbiddenException extends Exception {}
export class UnauthorizedException extends Exception {}
