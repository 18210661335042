import {createActions} from 'redux-actions';
import * as actionTypes from './actionTypes';

export const {
  getTerms,
  getTermsSuccess,
  getTermsFailure,
  getDiscountTerms,
  getDiscountTermsSuccess,
  getDiscountTermsFailure
} = createActions(
  actionTypes.GET_TERMS,
  actionTypes.GET_TERMS_SUCCESS,
  actionTypes.GET_TERMS_FAILURE,
  actionTypes.GET_DISCOUNT_TERMS,
  actionTypes.GET_DISCOUNT_TERMS_SUCCESS,
  actionTypes.GET_DISCOUNT_TERMS_FAILURE
);
