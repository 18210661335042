import produce from 'immer';
import * as actionTypes from './actionTypes';

const defaultState = {
  response: ''
}

const requests = produce((draft, {type, payload}) => {
  const newState = draft;

  switch (type) {
    case actionTypes.POST_REQUEST_DATA_SUCCESS:
      newState.response = payload;
      break;

    default:
  }
}, defaultState);

export default requests;
