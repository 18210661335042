import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import {
  getArticles,
  getArticlesSuccess,
  getArticlesFailure,
  getArticleDetails,
  getArticleDetailsSuccess,
  getArticleDetailsFailure
} from '../../reducers/Articles/actions';

const ARTICLES_URL = ({page, ItemsPerPage }) => `${process.env.GATSBY_XELLA_BACKEND}/api/articles?Page=${page}&ItemsPerPage=${ItemsPerPage}`;
const ARICLE_DETAILS_URL = ({id}) => `${process.env.GATSBY_XELLA_BACKEND}/api/articles/${id}`;

function* getArticlesSaga({payload}) {
  try {
    const res = yield getRequest(ARTICLES_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getArticlesSuccess(body));
    }
  } catch (error) {
    yield put(getArticlesFailure(error));
  }
}

function* getArticleDetailsSaga({payload}) {
  try {
    const res = yield getRequest(ARICLE_DETAILS_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getArticleDetailsSuccess(body));
    }
  } catch (error) {
    yield put(getArticleDetailsFailure(error));
  }
}

const articlesSagas = [
  takeLatest(getArticles, getArticlesSaga),
  takeLatest( getArticleDetails,getArticleDetailsSaga)
]


export default articlesSagas;
