import {createActions} from 'redux-actions';
import * as actionTypes from './actionTypes';

export const {
  getVideos,
  getVideosSuccess,
  getVideosFailure
} = createActions(
  actionTypes.GET_VIDEOS,
  actionTypes.GET_VIDEOS_SUCCESS,
  actionTypes.GET_VIDEOS_FAILURE
);
