import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import {
  getVideos,
  getVideosSuccess,
  getVideosFailure
} from '../../reducers/Videos/actions';


const GET_VIDEOS_URL = ({page}) => `${process.env.GATSBY_XELLA_BACKEND}/api/videos?Page=${page}`;

function* getVideosSaga({payload}) {
  try {
    const res = yield getRequest(GET_VIDEOS_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getVideosSuccess(body));
    }
  } catch (error) {
    yield put(getVideosFailure(error));
  }
}

const videoSagas = [
  takeLatest(getVideos, getVideosSaga)
]

export default videoSagas;
