import produce from 'immer';
import * as actionTypes from './actionTypes';

const defaultState = {
  gdpr: null,
  common: null,
  discount: null
}

const terms = produce((draft, {type, payload}) => {
  const newState = draft;

  switch (type) {
    case actionTypes.GET_TERMS_SUCCESS:
      if (payload.type === 'Gdpr') {
        newState.gdpr = payload;
      }
      else {
        newState.common = payload;
      }
      break;

    case actionTypes.GET_DISCOUNT_TERMS_SUCCESS:
      newState.discount = payload;
      break;

    default:
  }
}, defaultState);

export default terms;
