import produce from "immer";
import * as actionTypes from "./actionTypes";

const defaultState = {
  data: []
};

const products = produce((draft, { type, payload }) => {
  const newState = draft;

  switch (type) {
    case actionTypes.GET_PRODUCTS_SUCCESS:
      newState.data = payload
      break;

    case actionTypes.POST_PRODUCT_SUCCESS:
      break;

    default:
  }
}, defaultState);

export default products;
