import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  postRequest,
  handleResponse
} from '../../utils/requests'

import {
  getGuides,
  getGuidesSuccess,
  getGuidesFailure,
  postData,
  postDataSuccess,
  postDataFailure
} from '../../reducers/BuildingGuide/actions.js';


const GET_GUIDES_URL = ({page}) => `${process.env.GATSBY_XELLA_BACKEND}/api/guides?Page=${page}`;
const POST_DATA_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/guides/send`;

function* getGuidesSaga({payload}) {
  try {
    const res = yield getRequest(GET_GUIDES_URL(payload));
    const body = yield call(handleResponse, res);

    if (!!res && res.status === 200) {
      yield put(getGuidesSuccess(body));
    }
  } catch (error) {
    yield put(getGuidesFailure(error));
  }
}

function* posDataSaga({payload}) {
  try {
    const res = yield postRequest(POST_DATA_URL(), payload);

    if (!!res && res.status === 204) {
      yield put(postDataSuccess());
    }
  } catch (error) {
    yield put(postDataFailure(error));
  }
}

const guidesSagas = [
  takeLatest(getGuides, getGuidesSaga),
  takeLatest(postData, posDataSaga)
]

export default guidesSagas
