// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-building-guide-index-backup-js": () => import("./../../../src/pages/building-guide/index-backup.js" /* webpackChunkName: "component---src-pages-building-guide-index-backup-js" */),
  "component---src-pages-building-guide-index-js": () => import("./../../../src/pages/building-guide/index.js" /* webpackChunkName: "component---src-pages-building-guide-index-js" */),
  "component---src-pages-building-request-index-js": () => import("./../../../src/pages/building-request/index.js" /* webpackChunkName: "component---src-pages-building-request-index-js" */),
  "component---src-pages-construction-materials-index-js": () => import("./../../../src/pages/construction-materials/index.js" /* webpackChunkName: "component---src-pages-construction-materials-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-discounts-index-js": () => import("./../../../src/pages/discounts/index.js" /* webpackChunkName: "component---src-pages-discounts-index-js" */),
  "component---src-pages-discounts-terms-of-use-index-js": () => import("./../../../src/pages/discounts-terms-of-use/index.js" /* webpackChunkName: "component---src-pages-discounts-terms-of-use-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/FAQ/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-gdpr-index-js": () => import("./../../../src/pages/GDPR/index.js" /* webpackChunkName: "component---src-pages-gdpr-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-index-js": () => import("./../../../src/pages/map/index.js" /* webpackChunkName: "component---src-pages-map-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-single-index-js": () => import("./../../../src/pages/news-single/index.js" /* webpackChunkName: "component---src-pages-news-single-index-js" */),
  "component---src-pages-presentations-index-js": () => import("./../../../src/pages/presentations/index.js" /* webpackChunkName: "component---src-pages-presentations-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-service-details-index-js": () => import("./../../../src/pages/service-details/index.js" /* webpackChunkName: "component---src-pages-service-details-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-single-discount-index-js": () => import("./../../../src/pages/single-discount/index.js" /* webpackChunkName: "component---src-pages-single-discount-index-js" */),
  "component---src-pages-single-project-index-js": () => import("./../../../src/pages/single-project/index.js" /* webpackChunkName: "component---src-pages-single-project-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-videos-index-js": () => import("./../../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */),
  "component---src-pages-walls-solutions-silka-index-js": () => import("./../../../src/pages/walls-solutions-silka/index.js" /* webpackChunkName: "component---src-pages-walls-solutions-silka-index-js" */),
  "component---src-pages-walls-solutions-ytong-index-js": () => import("./../../../src/pages/walls-solutions-ytong/index.js" /* webpackChunkName: "component---src-pages-walls-solutions-ytong-index-js" */)
}

