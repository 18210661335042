import produce from 'immer';
import * as actionTypes from './actionTypes';

const defaultState = {
  data: []
}

const map = produce((draft, {type, payload}) => {
  const newState = draft;

  switch (type) {
    case actionTypes.GET_MAP_DATA_SUCCESS:
      newState.data = payload
      break;

    default:
  }
}, defaultState);

export default map;
