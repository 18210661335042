export const POST_REQUEST_DATA = 'POST_REQUEST_DATA' 
export const POST_REQUEST_DATA_SUCCESS = 'POST_REQUEST_DATA_SUCCESS' 
export const POST_REQUEST_DATA_FAILURE = 'POST_REQUEST_DATA_FAILURE' 

export const POST_PROJECT_REQUEST = 'POST_PROJECT_REQUEST' 
export const POST_PROJECT_REQUEST_SUCCESS = 'POST_PROJECT_REQUEST_SUCCESS' 
export const POST_PROJECT_REQUEST_FAILURE = 'POST_PROJECT_REQUEST_FAILURE' 

export const POST_FIELD_COUNTER = 'POST_FIELD_COUNTER'
export const POST_FIELD_COUNTER_SUCCESS = 'POST_FIELD_COUNTER_SUCCESS'
export const POST_FIELD_COUNTER_FAILURE = 'POST_FIELD_COUNTER_FAILURE'