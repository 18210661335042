import {createActions} from 'redux-actions';
import * as actionTypes from './actionTypes';

export const {
  getProjects,
  getProjectsSuccess,
  getProjectsFailure,
  getProjectDetails,
  getProjectDetailsSuccess,
  getProjectDetailsFailure,
} = createActions(
  actionTypes.GET_PROJECTS,
  actionTypes.GET_PROJECTS_SUCCESS,
  actionTypes.GET_PROJECTS_FAILURE,
  actionTypes.GET_PROJECT_DETAILS,
  actionTypes.GET_PROJECT_DETAILS_SUCCESS,
  actionTypes.GET_PROJECT_DETAILS_FAILURE
);
