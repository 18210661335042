import homeSagas from './Home';
import newsSagas from './News';
import articlesSagas from './Articles'
import presentationsSagas from './Presentations';
import videosSagas from './Videos'
import projectsSagas from './Projects'
import guidesSagas from './BuildingGuide'
import discountsSagas from './Discounts';
import mapDataSagas from './Map';
import requestSagas from './Requests'
import termsSagas from './Terms'
import aboutSagas from './About';
import productsSagas from './Products';

export default [
  ...homeSagas, 
  ...newsSagas, 
  ...articlesSagas, 
  ...presentationsSagas, 
  ...videosSagas,
  ...projectsSagas,
  ...guidesSagas,
  ...discountsSagas,
  ...mapDataSagas,
  ...requestSagas,
  ...termsSagas,
  ...aboutSagas,
  ...productsSagas
];
