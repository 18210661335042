import "./src/styles/global.scss";
import wrapWithProvider from "./wrapWithProvider";
import localReducer from "./src/reducers";
import localSaga from "./src/sagas";

export const wrapRootElement = ({ element }) =>
  wrapWithProvider({
    element,
    localReducer,
    localSaga,
  });
