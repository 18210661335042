import { createActions } from "redux-actions";
import * as actionTypes from "./actionTypes";

export const {
  getArchitects,
  getArchitectsSuccess,
  getArchitectsFailure,
  getArchitectDetails,
  getArchitectDetailsSuccess,
  getArchitectDetailsFailure,
  getBuilders,
  getBuildersSuccess,
  getBuildersFailure,
  getBuildersDetails,
  getBuildersDetailsSuccess,
  getBuildersDetailsFailure,
  getDealers,
  getDealersSuccess,
  getDealersFailure,
  getDealersDetails,
  getDealersDetailsSuccess,
  getDealersDetailsFailure,
  filterService,
  filterServiceSuccess,
  filterServiceFailure,
  setFilters,
  resetCurrentPage,
} = createActions(
  actionTypes.GET_ARCHITECTS,
  actionTypes.GET_ARCHITECTS_SUCCESS,
  actionTypes.GET_ARCHITECTS_FAILURE,
  actionTypes.GET_ARCHITECT_DETAILS,
  actionTypes.GET_ARCHITECT_DETAILS_SUCCESS,
  actionTypes.GET_ARCHITECT_DETAILS_FAILURE,
  actionTypes.GET_BUILDERS,
  actionTypes.GET_BUILDERS_SUCCESS,
  actionTypes.GET_BUILDERS_FAILURE,
  actionTypes.GET_BUILDERS_DETAILS,
  actionTypes.GET_BUILDERS_DETAILS_SUCCESS,
  actionTypes.GET_BUILDERS_DETAILS_FAILURE,
  actionTypes.GET_DEALERS,
  actionTypes.GET_DEALERS_SUCCESS,
  actionTypes.GET_DEALERS_FAILURE,
  actionTypes.GET_DEALERS_DETAILS,
  actionTypes.GET_DEALERS_DETAILS_SUCCESS,
  actionTypes.GET_DEALERS_DETAILS_FAILURE,
  actionTypes.FILTER_SERVICE,
  actionTypes.FILTER_SERVICE_SUCCESS,
  actionTypes.FILTER_SERVICE_FAILURE,
  actionTypes.SET_FILTERS,
  actionTypes.RESET_CURRENT_PAGE
);
