import produce from 'immer';
import * as actionTypes from './actionTypes';

const defaultState = {
  data: [],
  total_pages: null,
  current_page: 1,
  user: {
    name: '',
    phone: '',
    mail: '',
    location: ''
  }
}

const guides = produce((draft, {type, payload}) => {
  const newState = draft;

  switch (type) {
    case actionTypes.GET_GUIDES_SUCCESS:
      newState.data = payload.items;
      newState.current_page = payload.current_page;
      newState.total_pages = payload.total_pages;
      break;

    case actionTypes.SET_USER_DATA:
      let field = Object.keys(payload)[0]
      let aValue = Object.values(payload)[0] 
      newState.user = {
        ...newState.user,
        [field]: aValue
      }
      break;

    default:
  }
}, defaultState);

export default guides;
