import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  handleResponse
} from '../../utils/requests'

import {
 getTerms,
 getTermsSuccess,
 getTermsFailure,
 getDiscountTerms,
 getDiscountTermsSuccess,
 getDiscountTermsFailure
} from '../../reducers/Terms/actions';


// const POST_DATA_URL = ({type}) => `${process.env.GATSBY_XELLA_BACKEND}/api/terms/${type}`;
const GET_TERMS_URL = ({type}) => `${process.env.GATSBY_XELLA_BACKEND}/api/terms/${type}/all`;


function* getTermsSaga({payload}) {
  try {
    const res = yield getRequest(GET_TERMS_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getTermsSuccess(body));
    }
  } catch (error) {
    yield put(getTermsFailure(error));
  }
}

function* getDiscountTermsSaga({payload}) {
  try {
    const res = yield getRequest(GET_TERMS_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getDiscountTermsSuccess(body));
    }
  } catch (error) {
    yield put(getDiscountTermsFailure(error));
  }
}

const termsSagas = [
  takeLatest(getTerms, getTermsSaga),
  takeLatest(getDiscountTerms, getDiscountTermsSaga),
]

export default termsSagas
