import {put, takeLatest, call} from 'redux-saga/effects';
import { 
  getRequest,
  postRequest,
  handleResponse
} from '../../utils/requests'

import {
  getAboutInfo,
  getAboutInfoSuccess,
  getAboutInfoFailre,
  getContactsInfo,
  getContactsInfoSuccess,
  getContactsInfoFailure,
  postContactsInfo,
  postContactsInfoSuccess,
  postContactsInfoFailure,
  getFaqInfo,
  getFaqInfoSuccess,
  getFaqInfoFailure
} from '../../reducers/About/actions';

const GET_ABOUTUS_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/aboutUs`;
const GET_CONTACTS_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/contacts`;
const POST_CONTACTS_URL = () => `${process.env.GATSBY_XELLA_BACKEND}/api/contacts/send-inquiry`;
const GET_FAQ_URL = ({type, page}) => `${process.env.GATSBY_XELLA_BACKEND}/api/faq/${type}/all?Page=${page}&ItemsPerPage=1000`;

function* getAboutSaga({payload}) {
  try {
    const res = yield getRequest(GET_ABOUTUS_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getAboutInfoSuccess(body));
    }
  } catch (error) {
    yield put(getAboutInfoFailre(error));
  }
}

function* getContactsSaga({payload}) {
  try {
    const res = yield getRequest(GET_CONTACTS_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getContactsInfoSuccess(body));
    }
  } catch (error) {
    yield put(getContactsInfoFailure(error));
  }
}

function* postContactsSaga({payload}) {
  try {
    const res = yield postRequest(POST_CONTACTS_URL(), payload);
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 204) {
      yield put(postContactsInfoSuccess(body));
    }
  } catch (error) {
    yield put(postContactsInfoFailure(error));
  }
}

function* getFaqSaga({payload}) {
  try {
    const res = yield getRequest(GET_FAQ_URL(payload));
    const body = yield call(handleResponse, res);
    if (!!res && res.status === 200) {
      yield put(getFaqInfoSuccess(body));
    }
  } catch (error) {
    yield put(getFaqInfoFailure(error));
  }
}

const aboutSagas = [
  takeLatest(getAboutInfo, getAboutSaga),
  takeLatest(getContactsInfo, getContactsSaga),
  takeLatest(postContactsInfo, postContactsSaga),
  takeLatest(getFaqInfo, getFaqSaga)
]


export default aboutSagas;
