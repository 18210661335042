import {createActions} from 'redux-actions'
import * as actionTypes from './actionTypes'

export const {
  getArticles,
  getArticlesSuccess,
  getArticlesFailure,
  getArticleDetails,
  getArticleDetailsSuccess,
  getArticleDetailsFailure,
} = createActions(
  actionTypes.GET_ARTICLES,
  actionTypes.GET_ARTICLES_SUCCESS,
  actionTypes.GET_ARTICLES_FAILURE,
  actionTypes.GET_ARTICLE_DETAILS,
  actionTypes.GET_ARTICLE_DETAILS_SUCCESS,
  actionTypes.GET_ARTICLE_DETAILS_FAILURE
)
