import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import * as actionTypes from './actionTypes';
import * as serviceTypes from '../Services/actionTypes'
import * as requestTypes from '../Requests/actionTypes'
import * as buildingGuideTypes from '../BuildingGuide/actionTypes'
import * as productsTypes from '../Products/actionTypes'
import * as discountsTypes from '../Discounts/actionTypes'
import * as aboutTypes from '../About/actionTypes'

const defaultState = {
  serviceType: '',
  cities: [],
  districts: [],
  city: null,
  districtId: null,
  serviceId: null,
  successModal: false,
  bePartnerSuccess: false,
  bePartnerFailure: false,
  errorModal: false,
  lastNew: null,
  lastArticle: null,
  lastProject: null,
  partnerType: null,
  guid: uuidv4()
}

const returnService = (href) => {
  switch (href) {
    case "Архитектурно бюро":
      return "architects";
    case "Строителна компания":
      return "builders";
    case "Дистрибутори":
      return "dealers";

    default:
      return href;
  }
}

const home = produce((draft, { type, payload }) => {
  const newState = draft;

  switch (type) {
    case actionTypes.SET_SERVICE_TYPE:
      newState.serviceType = returnService(payload.type);
      break;

    case actionTypes.SET_CITY:
      newState.guid = uuidv4()
      newState.city = payload
      break;

    case serviceTypes.SET_FILTERS:
      newState.guid = uuidv4()
      newState.selectedFilters = payload
      break;

    case actionTypes.SET_DISTRICT:
      newState.districtId = payload
      break;

    case actionTypes.GET_CITIES_SUCCESS:
      newState.cities = payload
      break;

    case actionTypes.GET_DISTRICTS_SUCCESS:
      newState.districts = payload
      break;

    case actionTypes.POST_BE_PARTNER_SUCCESS:
      newState.bePartnerSuccess = true;
      newState.partnerType = payload.partnerType
      break;

    case actionTypes.POST_BE_PARTNER_FAILURE:
      newState.bePartnerFailure = true
      break;

    case requestTypes.POST_PROJECT_REQUEST_SUCCESS:
      newState.successModal = true
      break;

    case buildingGuideTypes.POST_DATA_SUCCESS:
      newState.successModal = true
      break;

    case actionTypes.SET_MODAL:
      newState.successModal = !newState.successModal
      break;

    case actionTypes.SET_BE_PARTNER:
      newState.bePartnerSuccess = false
      newState.bePartnerFailure = false
      break;

    case actionTypes.GET_LAST_NEW_SUCCESS:
      newState.lastNew = payload
      break;

    case actionTypes.GET_LAST_ARTICLE_SUCCESS:
      newState.lastArticle = payload
      break;

    case actionTypes.GET_LAST_PROJECT_SUCCESS:
      newState.lastProject = payload
      break;

    case productsTypes.POST_PRODUCT_SUCCESS:
      newState.successModal = true
      break;

    case discountsTypes.POST_DISCOUNT_SUCCESS:
      newState.successModal = true
      break;

    case requestTypes.POST_PROJECT_REQUEST_FAILURE:
      newState.errorModal = true
      newState.successModal = true
      break;

    case aboutTypes.POST_CONTACTS_INFO_SUCCESS:
      newState.successModal = true
      break;
      
    default:
  }
}, defaultState);

export default home;
