import { createActions } from "redux-actions";
import * as actionTypes from "./actionTypes";

export const {
  postRequestData,
  postRequestDataSuccess,
  postRequestDataFailure,
  postProjectRequest,
  postProjectRequestSuccess,
  postProjectRequestFailure,
  postFieldCounter,
  postFieldCounterSuccess,
  postFieldCounterFailure,
} = createActions(
  actionTypes.POST_REQUEST_DATA,
  actionTypes.POST_REQUEST_DATA_SUCCESS,
  actionTypes.POST_REQUEST_DATA_FAILURE,
  actionTypes.POST_PROJECT_REQUEST,
  actionTypes.POST_PROJECT_REQUEST_SUCCESS,
  actionTypes.POST_PROJECT_REQUEST_FAILURE,
  actionTypes.POST_FIELD_COUNTER,
  actionTypes.POST_FIELD_COUNTER_SUCCESS,
  actionTypes.POST_FIELD_COUNTER_FAILURE
);
