import {createActions} from 'redux-actions';
import * as actionTypes from './actionTypes';

export const {
  getNews,
  getNewsSuccess,
  getNewsFailure,
  getNewsDetails,
  getNewsDetailsSuccess,
  getNewsDetailsFailure,
} = createActions(
  actionTypes.GET_NEWS,
  actionTypes.GET_NEWS_SUCCESS,
  actionTypes.GET_NEWS_FAILURE,
  actionTypes.GET_NEWS_DETAILS,
  actionTypes.GET_NEWS_DETAILS_SUCCESS,
  actionTypes.GET_NEWS_DETAILS_FAILURE
);
