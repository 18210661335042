import React from "react";
import { Provider } from "react-redux";
import { BreakpointProvider } from "./src/utils/breakpoint";
import createStore from "./src/store";

const queries = {
  xxs: "(max-width: 380px)",
  xs: "(max-width: 576px)",
  sm: "(max-width: 768px)",
  md: "(max-width: 992px)",
  lg: "(max-width: 1200px)",
  xl: "(max-width: 1400px)",
  or: "(orientation: portrait)", // we can check orientation also
};

export default ({ element, localReducer, localSaga }) => {
  const store = createStore({ localReducer, localSaga });

  return (
    <Provider store={store}>
      <BreakpointProvider queries={queries}>{element}</BreakpointProvider>
    </Provider>
  );
};
