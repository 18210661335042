import produce from "immer";
import * as actionTypes from "./actionTypes";

const defaultState = {
  data: [],
  total_pages: null,
  current_page: 1,
  details: null,
};

const projects = produce((draft, { type, payload }) => {
  const newState = draft;

  switch (type) {
    case actionTypes.GET_PROJECTS_SUCCESS:
      newState.data = payload.items;
      newState.current_page = payload.current_page;
      newState.total_pages = payload.total_pages;
      break;

    case actionTypes.GET_PROJECT_DETAILS_SUCCESS:
      newState.details = payload;
      break;

    default:
  }
}, defaultState);

export default projects;
